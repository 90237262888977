import { defineStore } from "pinia";
import { useNotification } from "@kyvg/vue3-notification";

export const useCartStore = defineStore("cart", {
  state: () => ({
    isLoading: false,
    updateLoading: false,
    cartRedirecting: false,
    needUpsert: false,
    cart_id: "",
    cart_url: "",
    cart_redirect_back: "", // https://checkout.dev.useflik.com/cart/uuid?back={{cart_redirect_back}}
    cart_shared_url: "",
    query_cart_id: "", // https://checkout.dev.useflik.com/cart?id={{query_cart_id}}
    discount_amount: 0,
    discount_lines: [] as DiscountLines[],
    errors: [],
    has_ppo: false,
    is_area_restricted: false,
    items: [] as CartItem[],
    account: {
      bypass_phone_input: false,
      email: "",
      phone: "",
    },
    metadata: {
      flik_voucher: false,
      merchant_pixel_ids: [],
      store_app_id: "",
      optional_email: false,
      prefill: {
        email: "",
        fullname: "",
      },
    },
    merchant_order_id: [],
    notes: [],
    order_level_discounts: [],
    pickup_warehouses: [] as PickupWarehouse[],
    redirect_cart_id: "",
    removable_discounts: [],
    shareable: true,
    shipment_required: true,
    subtotal_amount: 0,
    subtotal_before_discount: 0,
    total_amount: 0,
    total_discount_amount: 0,
    type: "FLIK",
    option: {
      redirect_url: "",
    },
    totalOrder: 0,
    singleMerchant: "",
    singleMerchantWarehouse: null,
    merchantItemsWh: null as any,
    merchantItemsMid: null as any,
    merchantCount: 0,
    merchantIds: [] as string[],
  }),
  getters: {
    totalQuantity: (state) => {
      let total = 0;
      state.items.forEach((item: any) => {
        total += item.qty;
      });
      return total;
    },
    countItems: (state) => {
      return state.items.length;
    },
    isInvoiceCart: (state) => {
      return !state.shipment_required || state.type === "MANUAL_ORDER";
    },
    isPlatformCart: (state) => {
      return (
        state.type === "SHOPIFY" ||
        state.type === "WOOCOMMERCE" ||
        state.type === "PLATFORM"
      );
    },
    isManualOrder: (state) => {
      return state.type === "MANUAL_ORDER";
    },
    isGeneralCart: (state) => {
      return state.type === "FLIK";
    },
    getMerchantName: (state) => {
      let mname = "";
      if (state.merchantCount === 1) {
        mname = state.items[0].merchant_name;
      }
      return mname;
    },
    merchantWeb: (state) => {
      let strUrl = "https://flik.co.id";
      if (state.option.redirect_url !== "") {
        const { protocol, hostname } = new URL(state.option.redirect_url);
        if (hostname) {
          strUrl = `${protocol}//${hostname}`;
        }
      }
      return strUrl;
    },
    merchantLogo: (state) => {
      let mlogo = "";
      if (state.items.length > 0) {
        mlogo = state.items[0].merchant_logo;
      }
      return mlogo;
    },
  },
  actions: {
    resetState() {
      console.log("stores/cart.ts => resetState");
      this.$reset();
    },
    setOptionalEmailToken(token: string) {
      if (token && token !== "") {
        const auth = useAuthStore();
        auth.setAccessToken(token);
        auth.setLoggedIn(true);
        if (auth.accessToken === token && auth.loggedIn) {
          console.log("optionalEmailToken auth.accessToken:", auth.accessToken);
          const ppo = usePPOStore();
          ppo.isLoading = false;
        }
      } else {
        console.warn("no optionalEmailToken");
      }
    },
    setCartRedirectBack(url: string) {
      this.cart_redirect_back = url;
    },
    setCartSharedURL(url: string) {
      this.cart_shared_url = url;
    },
    addTotalOrder(addValue = 0) {
      this.totalOrder = this.total_amount + addValue;
    },
    setCartLoading(value: boolean) {
      setTimeout(() => {
        this.isLoading = value;
      }, 200);
    },
    setCartRedirecting(value: boolean) {
      this.cartRedirecting = value;
    },
    setCartData(params: any) {
      if (params?.address) delete params.address;
      if (params?.created_at) delete params.created_at;
      if (params?.updated_at) delete params.updated_at;

      console.log(
        "stores/cart.ts => setCartData => items hasLength :",
        hasLength(params.items),
      );
      let items = [];
      if (hasLength(params.items)) {
        items = params.items.map((item: any) => {
          return {
            ...item,
            is_ppo: false,
          };
        });

        // group cart items by merchant warehouse_id
        const groupByWh = items.reduce((r: any, a: any) => {
          // a.subtotal = a.sell_price * a.qty || 0
          r[a.warehouse_id] = [...(r[a.warehouse_id] || []), a];
          return r;
        }, {});
        // assign group to cart
        this.merchantItemsWh = groupByWh;

        // group cart items by merchant_id
        const groupByMid = items.reduce((r: any, a: any) => {
          r[a.merchant_id] = [...(r[a.merchant_id] || []), a];
          return r;
        }, {});
        this.merchantItemsMid = groupByMid;

        const merchantCount = Object.keys(groupByWh).length;
        this.merchantCount = merchantCount;

        const merchantIds: string[] = [];

        items.forEach((item: any) => {
          if (!merchantIds.includes(item.merchant_id)) {
            merchantIds.push(item.merchant_id);
          }
        });
        this.merchantIds = merchantIds;

        if (merchantCount === 1) {
          this.singleMerchant = items[0].merchant_id;
          this.singleMerchantWarehouse = params.items[0].wh || null;
        }
      }

      // return shipping option to delivery
      if (!hasLength(params.pickup_warehouses)) {
        useUiStore().shipType = "delivery";
        useUiStore().selectedWarehouse = null;
      }
      // always reset selectedWarehouse
      if (hasLength(params.pickup_warehouses)) {
        useUiStore().selectedWarehouse = null;
      }

      this.$patch({ ...params, items, totalOrder: params.total_amount || 0 });
    },
    async addItem(items: UpdatedCartItem[]) {
      console.log("addItem:");
      this.updateLoading = true;
      const { $api: api } = useNuxtApp();
      const itemToAdd: UpdatedCart = {
        items,
        type: "PRODUCT_INTERNAL",
      };
      const resp = await api.cart.postItem(itemToAdd);
      const cartData = resp.data.value?.data;
      console.log("addItem cartData:", cartData);
      const reqStatus = resp.status.value;
      const statusCode = resp?.error.value?.statusCode || 0;
      if (reqStatus === "success" && cartData) {
        this.setCartData(cartData);
        if (cartData?.account) {
          const shopper = useShopperStore();
          shopper.flags.is_guest = true;
          shopper.account.email = cartData?.account?.email;
          shopper.account._id = cartData?.account?._id;
        }

        this.updateLoading = false;
        return true;
      }

      if (reqStatus === "error" && statusCode === 401) {
        const auth = useAuthStore();
        this.resetState();
        auth.doLogout();
      }
      usePaymentStore().isLoading = false;
      useShippingStore().isCalculating = false;
    },
    async replaceGeneralCartItems(cartId: string) {
      const { $api: api } = useNuxtApp();
      const request: ReplaceCartItemRequest = { origin_cart_id: cartId };
      const resp = await api.cart.replaceGeneralCartItems(request);
      console.log("stores/cart.ts => replaceGeneralCartItems => resp", resp);
      this.$reset();
    },
    async updatePlatformCartItem(cartId: string, items: UpdatedCartItem[]) {
      this.updateLoading = true;
      const { $api: api } = useNuxtApp();
      const updateItem: UpdatedPlatformCart = { items };
      const resp = await api.cart.postPlatformCartItem(cartId, updateItem);
      const cartData = resp.data.value?.data;
      console.log(
        "stores/cart.ts => updatePlatformCartItem => cartData:",
        cartData,
      );
      const reqStatus = resp.status.value;
      const statusCode = resp?.error.value?.statusCode || 0;
      if (reqStatus === "success" && cartData) {
        this.setCartData(cartData);
        this.updateLoading = false;
        return true;
      }

      if (reqStatus === "error" && statusCode === 401) {
        const auth = useAuthStore();
        this.resetState();
        auth.doLogout();
        this.updateLoading = false;
      }
      this.updateLoading = false;
      usePaymentStore().isLoading = false;
      useShippingStore().isCalculating = false;
    },
    async updateGeneralCartItem(items: UpdatedCartItem[]) {
      this.updateLoading = true;
      const { $api: api } = useNuxtApp();
      const updateItem: UpdatedCart = {
        items,
        type: "PRODUCT_INTERNAL",
      };
      const resp = await api.cart.postItem(updateItem);
      const cartData = resp.data.value?.data;
      console.log("updateItem cartData:", cartData);
      const reqStatus = resp.status.value;
      const statusCode = resp?.error.value?.statusCode || 0;
      if (reqStatus === "success" && cartData) {
        this.setCartData(cartData);
        this.updateLoading = false;
        return true;
      }

      if (reqStatus === "error" && statusCode === 401) {
        const auth = useAuthStore();
        this.resetState();
        auth.doLogout();
      }
      usePaymentStore().isLoading = false;
      useShippingStore().isCalculating = false;
    },
    async deleteItem(payload: any[]) {
      const { $api: api } = useNuxtApp();
      const deleteItem: any = { items: payload };
      const resp = await api.cart.postItem(deleteItem);
      const cartData = resp.data.value?.data;
      console.log("deleteItem cartData:", cartData);
      const reqStatus = resp.status.value;
      const statusCode = resp?.error.value?.statusCode || 0;
      if (reqStatus === "success" && cartData) {
        this.setCartData(cartData);
        return true;
      }

      if (reqStatus === "error" && statusCode === 401) {
        const auth = useAuthStore();
        this.resetState();
        auth.doLogout();
      }
    },
    async removePlatformCartItem(cartId: string, payload: any[]) {
      this.updateLoading = true;
      const { $api: api } = useNuxtApp();
      const deletedItems: RemovePlatformCartItem = { items: payload };
      const resp = await api.cart.deletePlatformCartItem(cartId, deletedItems);

      const cartData = resp.data.value?.data;
      console.log(
        "stores/cart.ts => removePlatformCartItem => cartData:",
        cartData,
      );
      const reqStatus = resp.status.value;
      const statusCode = resp?.error.value?.statusCode || 0;

      if (reqStatus === "success" && cartData) {
        this.setCartData(cartData);
        this.updateLoading = false;
        return true;
      }

      if (reqStatus === "error" && statusCode === 401) {
        const auth = useAuthStore();
        this.resetState();
        auth.doLogout();
      }
      usePaymentStore().isLoading = false;
      useShippingStore().isCalculating = false;
    },
    async removeGeneralCartItem(payload: any[]) {
      const { CARTSVC } = useRuntimeConfig().public;

      const { data } = await useFlikApi<ApiResponse>(
        `${CARTSVC}/v1/cart/items`,
        {
          method: "DELETE",
          body: { items: payload },
        },
      );
      const cartData: any = data?.value?.data || null;
      console.log("cartData:", cartData);
      const cartErrors = data?.value?.errors || [];
      console.log("cartErrors:", cartErrors);

      if (cartData?.cart_id) {
        this.setCartData(cartData);
        return true;
      }
    },
    async generateCartLink() {
      if (this.cart_id) {
        console.log("Generate short links from cart.cart_url");
        console.log(
          "stores/cart.ts => generateCartLink => cart.cart_redirect_back =>",
          this.cart_redirect_back,
        );

        let url = this.cart_url + "?shared=true";
        if (this.cart_redirect_back) {
          url += `&back=${this.cart_redirect_back}`;
        }

        const { data } = await useFlikApi<ShortenerResponse>(
          `shortener/v1/shortener`,
          {
            method: "POST",
            body: { urls: [url] },
          },
        );
        const resp = data?.value?.data || null;
        console.log("generateCartLink cartData:", resp);

        if (resp?.length && resp.length > 0) {
          this.setCartSharedURL(resp[0].link);
        } else {
          const cartErrors = data?.value?.errors || [];
          console.log("generateCartLink cartErrors:", cartErrors);
        }
      }
    },
    async getPublicGeneralCartByID(id: string) {
      const { $api: api } = useNuxtApp();
      this.$reset();
      this.setCartLoading(true);
      const resp = await api.cart.getGeneralCartByID(id);
      const cartData = resp.data.value?.data;
      console.log(
        "stores/cart.ts => getPublicGeneralCartByID => cartData.items ====>",
        cartData?.items,
      );

      if (cartData?.items.length) {
        this.needUpsert = true;
        this.setCartData(cartData);
      }

      this.query_cart_id = id;
      this.setCartLoading(false);
    },
    async getCart() {
      this.needUpsert = false;
      const { $api: api } = useNuxtApp();
      this.$reset();
      this.setCartLoading(true);
      const resp = await api.cart.getCart();
      const cartData = resp.data.value?.data;
      const reqStatus = resp.status.value;
      const statusCode = resp?.error.value?.statusCode || 0;

      if (reqStatus === "success" && cartData) {
        this.setCartData(cartData);
        this.metadata.optional_email = false;
        this.setCartLoading(false);
      }

      if (reqStatus === "error" && statusCode === 401) {
        const auth = useAuthStore();
        this.resetState();
        auth.doLogout();
        this.setCartLoading(false);
      }
      this.setCartLoading(false);
      usePaymentStore().isLoading = false;
      useShippingStore().isCalculating = false;
    },
    async getCartById(id: string, isShared: boolean) {
      this.needUpsert = false;
      this.setCartLoading(true);
      const { $api: api } = useNuxtApp();
      const resp = await api.cart.getCartPlatform(
        id,
        "kota jakarta selatan",
        isShared,
      );
      const cartResponse = resp.data.value;
      const cartData: any = cartResponse?.data || null;
      const cartStatus = cartData?.status || "";
      const cartErrors = cartResponse?.data?.errors || [];

      const reqStatus = resp.status.value;
      const statusCode = resp.data?.value?.statusCode || 0;
      const optionalEmail = cartData?.metadata?.optional_email || false;
      const optionalEmailToken =
        cartData?.metadata?.optional_email_token || false;

      const redirectId = cartData?.redirect_cart_id || "";

      return {
        cartResponse,
        cartStatus,
        cartData,
        cartErrors,
        statusCode,
        redirectId,
        reqStatus,
        optionalEmail,
        optionalEmailToken,
      };
    },
    async getCartId(id: string) {
      if (id.length) {
        const route = useRoute();
        const query = route.query;
        const isShared = query.shared === "true";

        const {
          cartData,
          cartErrors,
          statusCode,
          redirectId,
          reqStatus,
          cartStatus,
        } = await this.getCartById(id, isShared);

        console.log("getCartId cartData:", cartData);
        console.log("getCartId reqStatus:", reqStatus);
        console.log("getCartId statusCode:", statusCode);
        const cartId = cartData?.data?.cart_id || "";
        console.log("getCartId cartId:", cartId);
        if (statusCode === 403 || cartStatus === "unauthorized") {
          useNotification().notify({
            text: "Session expired. Please login again.",
            type: "info",
          });
          const auth = useAuthStore();
          auth.doLogout();
          navigateTo("/login", { replace: true });
        }
        if (statusCode === 403 && cartId !== "") {
          const auth = useAuthStore();
          const ui = useUiStore();
          const optionalEmailToken =
            cartData?.data?.metadata?.optional_email_token || "";

          auth.setLoggedIn(false);
          auth.setAccessToken("");
          ui.homePath = "/cart/" + cartId;

          console.log("getCartId after doLogout cartId:", cartId);
          console.log("getCartId optionalEmailToken:", optionalEmailToken);
          if (optionalEmailToken)
            this.setOptionalEmailToken(optionalEmailToken);
          else navigateTo(`/processing`, { replace: true });
        }
        if (statusCode === 401 || cartStatus === "unauthorized") {
          useNotification().notify({
            text: cartStatus,
            type: "error",
          });
          const auth = useAuthStore();
          auth.doLogout();
          if (route.name === 'cart-id') {
            // refresh so FE will do the get cart again after logout
            window.location.reload(); // FIXME: this is workaround for now, but it works
          } else {
            navigateTo("/login", { replace: true });
          }
        }

        if (redirectId) {
          console.log("query BEFORE:", query);

          this.setCartData({
            cart_id: "",
            items: [],
            redirect_cart_id: redirectId,
            cartRedirecting: true,
          });
          this.setCartLoading(true);
          if (query?.shared) delete query.shared;
          console.log("getCartId :", redirectId);
          console.log("query AFTER:", query);

          await navigateTo({
            path: `/cart/${redirectId}`,
            query,
            replace: true,
          });
        }

        // RUMA data handling
        if (
          cartData?.metadata?.prefill &&
          cartData?.metadata?.prefill?.email !== "" &&
          cartData?.metadata?.prefill?.fullname !== ""
        ) {
          this.setCartData(cartData);
          const auth = useAuthStore();
          auth.showLoginAsGuestForm();
        }
        // RUMA data handling

        if (
          reqStatus === "success" &&
          cartErrors.length === 0 &&
          cartData?.cart_id
        ) {
          this.setCartData({
            ...cartData,
            redirect_cart_id: "",
          });
          this.setCartRedirecting(false);
          this.setCartLoading(false);
        }

        if (reqStatus === "success" && cartErrors.length > 0) {
          this.resetState();
          this.setCartData({ errors: cartErrors });
          this.setCartRedirecting(false);
          this.setCartLoading(false);
          usePromoStore().$reset();

          console.log("cartStatus:", cartStatus);
          if (cartStatus === "error_notfound") {
            useNotification().notify({
              text: "Cart not found",
              type: "error",
            });
          } else {
            useNotification().notify({
              text: cartErrors[0],
              type: "error",
            });
          }
        }

        if (reqStatus === "error") {
          this.resetState();
        }

        if (reqStatus === "error" && statusCode === 401) {
          const auth = useAuthStore();
          this.setCartLoading(false);
          auth.doLogout();
        }

        this.setCartLoading(false);
        usePaymentStore().isLoading = false;
        useShippingStore().isCalculating = false;
      }
    },
    async getAffiliateConfig() {
      const { CARTSVC } = useRuntimeConfig().public;

      const { data } = await useFlikApi<AffiliateConfigResponse>(
        `${CARTSVC}/v1/affiliate/config`,
        { method: "GET" },
      );

      return data?.value?.data;
    },
    removeCartItem(item_id: string) {
      this.$patch((state) => {
        state.items = state.items.filter(
          (item: any) => item.item_id !== item_id,
        );
      });
    },
    setLocalCart() {
      this.needUpsert = true;
      if (hasLength(this.items)) {
        let total_amount = 0;
        let subtotal_amount = 0;
        let subtotal_before_discount = 0;
        let total_discount_amount = 0;

        this.items.forEach((item: any) => {
          total_amount += item.total_amount;
        });

        this.items.forEach((item: any) => {
          subtotal_amount += item.subtotal_amount;
        });

        this.items.forEach((item: any) => {
          // subtotal_before_discount += item.unit_price * item.qty;
          subtotal_before_discount += item.subtotal_amount;
        });

        this.items.forEach((item: any) => {
          total_discount_amount += item.discount_price;
        });

        this.$patch({
          type: "FLIK",
          shareable: false,
          totalOrder: total_amount,
          subtotal_amount,
          subtotal_before_discount,
          total_amount,
          total_discount_amount,
        });

        // group cart items by merchant warehouse_id
        const groupByWh = this.items.reduce((r: any, a: any) => {
          // a.subtotal = a.sell_price * a.qty || 0
          r[a.warehouse_id] = [...(r[a.warehouse_id] || []), a];
          return r;
        }, {});
        // assign group to cart
        this.merchantItemsWh = groupByWh;

        // group cart items by merchant_id
        const groupByMid = this.items.reduce((r: any, a: any) => {
          r[a.merchant_id] = [...(r[a.merchant_id] || []), a];
          return r;
        }, {});
        this.merchantItemsMid = groupByMid;

        const merchantCount = Object.keys(groupByWh).length;
        this.merchantCount = merchantCount;

        const merchantIds: string[] = [];

        this.items.forEach((item: any) => {
          if (!merchantIds.includes(item.merchant_id)) {
            merchantIds.push(item.merchant_id);
          }
        });
        this.merchantIds = merchantIds;

        if (merchantCount === 1) {
          this.singleMerchant = this.items[0].merchant_id;
          this.singleMerchantWarehouse = this.items[0].wh || null;
        }
      }
    },
    addToLocalCart(product: any, selected: any, qty: number) {
      console.log("addToLocalCart qty:", qty);
      console.log("addToLocalCart product:", product);

      let dynamic_attributes = {};

      if (selected.variant) {
        dynamic_attributes = selected.variant;
      }

      let total_amount = 0;
      let subtotal_amount = 0;

      if (selected.price.discount) {
        total_amount = selected.price.discount_price * qty || 0;
        subtotal_amount = selected.price.discount_price * qty || 0;
      } else {
        total_amount = selected.price.sell_price * qty || 0;
        subtotal_amount = selected.price.sell_price * qty || 0;
      }

      const cartItem: any = {
        item_id: selected.varid,
        merchant_id: product.merchant.id || "",
        merchant_item_id: product.merchant_prodid,
        name: product.name,
        qty,
        product_fresh: false,
        unit_price: selected.price.base_price,
        dynamic_attributes,
        weight: product.weight || 0,
        image_url: selected.image_url,
        product_slug: product.product_slug,
        merchant_slug: product.merchant_slug,
        stock_available: product.stock_available,
        is_custom: false,
        is_discount: selected.price.discount || false,
        use_variant: product.use_variant,
        sell_price: selected.price.base_price || 0,
        discount_price: selected.price.discount_price || 0,
        total_amount,
        subtotal_amount,
        merchant_name: product.merchant.name,
        merchant_logo: product.merchant.logo_url,
        warehouse_id: product.default_warehouse.id || "",
        volume: product.volume,
        info: null,
        show_qty: true,
        editable: false,
        wh: product.default_warehouse,
      };
      console.log("addToLocalCart cartItem:", cartItem);
      // push cartItem
      const findItem = this.items.find(
        (item: any) => item.item_id === cartItem.item_id,
      );

      console.log("findItem:", findItem);

      if (findItem) {
        const newQty = findItem.qty + cartItem.qty;

        const total_amount = findItem.total_amount + cartItem.total_amount || 0;
        const subtotal_amount =
          findItem.subtotal_amount + cartItem.subtotal_amount || 0;

        const newCartItem = {
          ...cartItem,
          qty: newQty,
          total_amount,
          subtotal_amount,
        };

        this.removeCartItem(cartItem.item_id);
        this.items.push(newCartItem);
      } else {
        this.items.push(cartItem);
      }
      // END push cartItem

      this.setLocalCart();
    },
    removeLocalCart(item: any) {
      this.removeCartItem(item.item_id);
      this.setLocalCart();
    },
    async setNote(cart_id: string, param: any) {
      const payload = {
        warehouse_id: param.warehouse_id,
        note: param.note,
      };

      const { CARTSVC } = useRuntimeConfig().public;

      const { data, error } = await useFlikApi<ApiResponse>(
        `${CARTSVC}/v1/cart/notes?id=${cart_id}`,
        { method: "PUT", body: payload },
      );
      const noteData: any = data?.value?.data || null;
      console.log("setNote data:", noteData);
      const noteErrors = error?.value?.data?.errors || [];
      console.log("setNote error:", noteErrors);
    },
  },
  persist: [
    {
      key: "nfcart",
      paths: [
        "cart_id",
        "cart_url",
        "has_ppo",
        "isGeneralCart",
        "isPlatformCart",
        "items",
        "merchantItemsWh",
        "merchantItemsMid",
        "shareable",
        "shipment_required",
        "singleMerchant",
        "singleMerchantWarehouse",
        "subtotal_amount",
        "subtotal_before_discount",
        "total_amount",
        "total_discount_amount",
        "totalOrder",
        "type",
      ],
      storage: persistedState.localStorage,
    },
  ],
});
